import Button from 'components/Button';
import MuiButton from '@material-ui/core/Button';
import { actions, selectors } from 'lib/redux/pdf';
import { useDispatch, useSelector } from 'react-redux';

const { getDocument, getLoading } = selectors;
const { generatePdf } = actions;

type Props = {
  id: string;
  title: string;
  payload: Record<string, string>;
  templateId: string;
  uuid: string;
  fileName: string;
};

const CreatePdfButton: React.FC<Props> = ({ id, title, payload, templateId, uuid, fileName }) => {
  const dispatch = useDispatch();
  const document = useSelector(getDocument(uuid, templateId));
  const loading = useSelector(getLoading());

  const onClick: () => void = () => {
    dispatch(generatePdf(uuid, templateId, payload, fileName));
  };

  return (
    <>
      {document?.downloadUrl ? (
        <MuiButton>
          <a target="_blank" href={document.downloadUrl} rel="noreferrer">
            {`Download ${title}`}
          </a>
        </MuiButton>
      ) : (
        <Button id={id} variant="contained" color="primary" onClick={onClick} loading={loading}>
          {`Create ${title}`}
        </Button>
      )}
    </>
  );
};

export default CreatePdfButton;
