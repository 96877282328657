import Label from 'components/Label';

const statusColor = (status: string) => {
  switch (status) {
    case 'succeeded':
      return 'success';
    case 'failed':
      return 'error';
    default:
      return 'primary';
  }
};

const PaymentStatus: React.FC<{
  className?: string;
  status: string;
}> = ({ className, status }) => {
  return (
    <Label className={className} color={statusColor(status)}>
      {status}
    </Label>
  );
};

export default PaymentStatus;
