import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';

export const getInitials = (name = '') =>
  name
    .replace(/\s+/, ' ')
    .split(' ')
    .slice(0, 2)
    .map((v) => v && v[0].toUpperCase())
    .join('');

export const connectionResultToNodeArray = <T = any>(connectionResult: any): T[] => {
  const array = connectionResult?.edges || [];
  return array.map((item: any) => item.node);
};

export const relayCursorToInt = (cursor: string) => {
  const buff = Buffer.from(cursor, 'base64');
  const text = buff.toString('utf-8').replace('arrayconnection:', '');
  return parseInt(text);
};

const isEmptyString = (value: any) => isEqual(value, '');

export const clearQueryObject = (query: Record<string, any>) => {
  return omitBy(omitBy(query, isNil), isEmptyString);
};

export const debounceCall = debounce((func) => func(), 500);

export const booleanToString = (bool?: boolean | null) => {
  if (bool === null || bool === undefined) {
    return bool;
  }

  return bool.toString();
};

export const stringToBoolean = (str?: string) => {
  if (str === '') return undefined;
  return str === 'true';
};

export const stringValueDifferentFromRemoteValue = (str: string, remoteValue: any) => {
  if (str === '' && remoteValue === null) return false;
  return str !== remoteValue;
};

export const remoteObjectEquals = (remoteObject: any, localObject: any) => {
  if (!remoteObject) {
    return false;
  }

  return !Object.keys(localObject).some((key) => {
    return localObject[key] !== remoteObject[key];
  });
};

export const stringToInt = ({ value, defaultValue }: { value?: any; defaultValue: any }): any => {
  const num = parseInt(value);
  return Number.isNaN(num) ? defaultValue : num;
};

export const stringToFloat = ({ value, defaultValue }: { value?: any; defaultValue: any }): any => {
  const num = parseFloat(value);
  return Number.isNaN(num) ? defaultValue : num;
};

export const login: (token: string) => Promise<any> = (token) => {
  return fetch('/api/login', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json;charset=utf-8',
    },
    body: JSON.stringify({ token }),
  });
};

export const formatPrice = (
  priceInCents: number,
  options: Intl.NumberFormatOptions = { currency: 'EUR' },
  locale = 'fr-FR'
) =>
  new Intl.NumberFormat(locale, {
    style: 'currency',
    ...options,
  }).format(priceInCents / 100);
