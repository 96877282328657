import {
  BookingQuery,
  ListPaymentsQuery,
  ListPaymentsQueryVariables,
  Payment,
  useListPaymentsQuery,
} from 'generated/graphql';
import { actions as notificationsActions } from 'lib/redux/notifications';
import { clearQueryObject, connectionResultToNodeArray, relayCursorToInt } from 'lib/utils';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import TableComponent from './table';

const perPage = 10;
const { addNotifications } = notificationsActions;

type Props = {
  booking?: BookingQuery['booking'];
};

const Payments: React.FC<Props> = ({ booking }) => {
  const [queryBefore, setQueryBefore] = useState<string | undefined>();
  const [queryAfter, setQueryAfter] = useState<string | undefined>();
  const [queryFirst, setQueryFirst] = useState<number | undefined>(perPage);
  const [queryLast, setQueryLast] = useState<number | undefined>();
  const dispatch = useDispatch();

  const rentalId = booking?.rental?.id;

  const variables = clearQueryObject({
    first: queryFirst,
    last: queryLast,
    before: queryBefore,
    after: queryAfter,
    rentalId,
  }) as ListPaymentsQueryVariables;

  let nodes: Payment[] = [];
  let connection = {} as ListPaymentsQuery['payments'];

  const { loading, error, data } = useListPaymentsQuery({
    variables,
  });

  if (error) {
    dispatch(addNotifications([{ severity: 'error', message: error.message }]));
  }

  if (!loading && data && data.payments) {
    nodes = connectionResultToNodeArray<Payment>(data.payments);
    connection = data.payments;
  }

  const getCurrentPage = () => {
    if (connection?.pageInfo?.startCursor) {
      return relayCursorToInt(connection?.pageInfo?.startCursor) / perPage;
    }
    return 0;
  };

  const currentPage = getCurrentPage();

  const onChangePage = async (newPage: number) => {
    if (newPage > currentPage) {
      setQueryFirst(perPage);
      setQueryAfter(connection?.pageInfo?.endCursor);
      setQueryLast(undefined);
      setQueryBefore(undefined);
    } else {
      setQueryFirst(undefined);
      setQueryAfter(undefined);
      setQueryLast(perPage);
      setQueryBefore(connection?.pageInfo?.startCursor);
    }
  };

  return (
    <TableComponent
      booking={booking}
      nodesCount={connection?.count || 0}
      nodes={nodes}
      perPage={perPage}
      currentPage={currentPage}
      onChangePage={onChangePage}
    />
  );
};

export default Payments;
