import {
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from '@material-ui/core';
import { BookingQuery, Payment } from 'generated/graphql';
import TableRowComponent from './tableRow';

const useStyles = makeStyles({
  root: {
    width: '100%',
  },
});

type Props = {
  nodesCount: number;
  nodes: Payment[];
  booking?: BookingQuery['booking'];
  currentPage: number;
  onChangePage: (newPage: number) => void;
  perPage: number;
};

export const TableElement: React.FC<{ booking?: BookingQuery['booking']; nodes: Payment[] }> = ({
  nodes,
  booking,
}) => (
  <TableContainer>
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Status</TableCell>
          <TableCell>Amount</TableCell>
          <TableCell>Description</TableCell>
          <TableCell>Guest</TableCell>
          <TableCell>Host</TableCell>
          <TableCell>Time</TableCell>
          <TableCell align="right">Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {nodes.map((payment) => (
          <TableRowComponent booking={booking} payment={payment} key={payment.id} />
        ))}
      </TableBody>
    </Table>
  </TableContainer>
);

const TableComponent: React.FC<Props> = ({
  booking,
  nodesCount,
  nodes,
  perPage,
  currentPage,
  onChangePage,
}) => {
  const classes = useStyles();

  return (
    <Paper className={classes.root}>
      <TableElement nodes={nodes} booking={booking} />
      <TablePagination
        component="div"
        count={nodesCount}
        onPageChange={(_event, page) => onChangePage(page)}
        page={currentPage}
        rowsPerPage={perPage}
        rowsPerPageOptions={[perPage]}
      />
    </Paper>
  );
};

export default TableComponent;
