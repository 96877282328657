import { Grid, IconButton, makeStyles, SvgIcon, TableCell, TableRow } from '@material-ui/core';
import { ArrowForward as ArrowForwardIcon } from '@material-ui/icons';
import MoneyOffIcon from '@material-ui/icons/MoneyOff';
import clsx from 'clsx';
import CreatePdfButton from 'components/CreatePdfButton';
import Label from 'components/Label';
import PaymentStatus from 'components/PaymentStatus';
import TypographyLink from 'components/TypographyLink';
import Link from 'next/link';
import { FormattedDate, FormattedNumber, useIntl } from 'react-intl';
import moment from 'moment';
import { BookingQuery, Payment } from 'generated/graphql';

const useStyles = makeStyles({
  succeeded: {
    color: '#72c89e',
  },
  failed: {
    color: '#f26b5b',
  },
});

const computePaymentPeriod: (houseShareStartDate: string, paymentDate: string) => string = (
  houseShareStartDate,
  paymentDate
) => {
  // 01 04 2021
  let periodStartDate = moment(houseShareStartDate);
  // 03 03 2021
  const tmpPaymentDate = moment(paymentDate);

  if (tmpPaymentDate.isBefore(periodStartDate)) {
    return `${periodStartDate.format('YYYY-MM-DD')} - ${periodStartDate
      .add(1, 'months')
      .format('YYYY-MM-DD')}`;
  }

  while (periodStartDate.isSameOrBefore(tmpPaymentDate)) {
    periodStartDate = periodStartDate.add(1, 'months');
  }

  const endDate = periodStartDate.format('YYYY-MM-DD');
  const startDate = periodStartDate.subtract(1, 'months').format('YYYY-MM-DD');

  return `${startDate} - ${endDate}`;
};

const Price = ({ payment }: { payment: any }) => {
  const classes = useStyles();

  const { amountInCents, currency, applicationFeeAmount } = payment;

  return (
    <Grid container direction="column">
      <Grid item>
        <FormattedNumber value={amountInCents / 100} style="currency" currency={currency} />
      </Grid>
      <Grid
        item
        className={clsx(payment.status === 'succeeded' ? classes.succeeded : classes.failed)}
      >
        <Grid container spacing={0} wrap="nowrap" alignItems="center">
          <Grid item>
            <MoneyOffIcon style={{ fontSize: 12 }} />
          </Grid>
          <Grid item>
            <FormattedNumber
              value={applicationFeeAmount / 100}
              style="currency"
              currency={currency}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

type Props = {
  payment: Payment;
  booking?: BookingQuery['booking'];
};

const TableRowComponent: React.FunctionComponent<Props> = ({ booking, payment }) => {
  const intl = useIntl();

  return (
    <TableRow hover>
      <TableCell>
        <PaymentStatus status={payment.status} />
        {!!payment.paymentDispute && <Label color="warning">Disputed</Label>}
      </TableCell>
      <TableCell>
        <Price payment={payment} />
      </TableCell>

      <TableCell>{payment.description}</TableCell>

      <TableCell>
        {payment.rental?.guestCustomer && (
          <TypographyLink
            variant="body2"
            href="/users/[id]"
            as={`/users/${payment.rental?.guestCustomer.userId}`}
          >
            {`${payment.rental?.guestCustomer?.firstName} ${payment.rental?.guestCustomer?.lastName}`}
          </TypographyLink>
        )}
      </TableCell>

      <TableCell>
        {payment.rental?.hostCustomer && (
          <TypographyLink
            variant="body2"
            href="/users/[id]"
            as={`/users/${payment.rental?.hostCustomer.userId}`}
          >
            {`${payment.rental?.hostCustomer?.firstName} ${payment.rental?.hostCustomer?.lastName}`}
          </TypographyLink>
        )}
      </TableCell>

      <TableCell>
        <FormattedDate
          value={payment.stripeCreated}
          year="numeric"
          month="numeric"
          day="numeric"
          hour="numeric"
          minute="numeric"
          hour12={false}
        />
      </TableCell>

      <TableCell align="right">
        {!!booking && payment.status === 'succeeded' && (
          <CreatePdfButton
            id={`pdf-receipt-${payment.id}`}
            title="receipt"
            payload={{
              guestName: `${booking.guest.firstName} ${booking.guest.lastName}`,
              hostName: `${booking.host.firstName} ${booking.host.lastName}`,
              roomAddress: booking.room.location.formattedAddress,
              amountPaid: (payment.amountInCents / 100).toString(),
              fees: (payment.applicationFeeAmount / 100).toString(),
              paymentDate: intl.formatDate(payment.stripeCreated, {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }),
              currentDate: intl.formatDate(new Date(), {
                year: 'numeric',
                month: 'numeric',
                day: 'numeric',
              }),
              period: computePaymentPeriod(booking.startDate, payment.stripeCreated),
              rentWithoutFees: (
                (payment.amountInCents - payment.applicationFeeAmount) /
                100
              ).toString(),
            }}
            fileName={`rent-receipt-${booking.guest.firstName}-${booking.guest.lastName}`}
            templateId={process.env.NEXT_PUBLIC_PDFMONKEY_RENT_RECEIPT_TEMPLATE_ID as string}
            uuid={payment.id}
          />
        )}
        {!!payment.paymentDispute && (
          <IconButton component="a" size="small">
            <Link
              href="/payment-disputes/[id]"
              as={`/payment-disputes/${payment.paymentDispute.id}`}
              passHref
            >
              <SvgIcon fontSize="small">
                <ArrowForwardIcon />
              </SvgIcon>
            </Link>
          </IconButton>
        )}
      </TableCell>
    </TableRow>
  );
};

export default TableRowComponent;
