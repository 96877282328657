import { Button as MuiButton, ButtonProps, CircularProgress } from '@material-ui/core';
import { Done as DoneIcon, ErrorOutline as ErrorOutlineIcon } from '@material-ui/icons';
import { selectors } from 'lib/redux/buttons';
import { useSelector } from 'react-redux';

const { succeeded: succeededSelector, failed: failedSelector } = selectors;

type Props = {
  id: string;
  loading: boolean;
  disabled?: boolean;
  children: React.ReactNode;
} & ButtonProps;

const ButtonContent = ({
  succeeded,
  failed,
  loading = false,
  children,
}: {
  succeeded: boolean;
  failed: boolean;
  loading: boolean;
  children: React.ReactNode;
}) => {
  if (succeeded) {
    return <DoneIcon style={{ color: 'inherit' }} />;
  }

  if (failed) {
    return <ErrorOutlineIcon style={{ color: 'inherit' }} />;
  }

  if (loading) {
    return <CircularProgress size={20} />;
  }

  return <>{children}</>;
};

const Button: React.FC<Props> = ({ children, id, loading, disabled, ...rest }) => {
  const succeeded = useSelector(succeededSelector(id));
  const failed = useSelector(failedSelector(id));

  const disabledAttr = disabled !== undefined ? disabled : succeeded || failed || loading;

  return (
    <MuiButton id={id} disabled={disabledAttr} {...rest}>
      <ButtonContent succeeded={succeeded} failed={failed} loading={loading}>
        {children}
      </ButtonContent>
    </MuiButton>
  );
};

export default Button;
